import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/page.js";
import vimyAerialImage from '../images/vimy-aerial.jpg';
import vimyStakedOaksImage from '../images/vimy-staked-trees.jpg';
import plantedVimyOak1Image from '../images/planted-vimy-oak-1.jpg';
import plantedVimyOak2Image from '../images/planted-vimy-oak-2.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Figure = makeShortcode("Figure");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Mission #1: Repatriate Oak Trees to Vimy Ridge`}</h2>
    <p>{`On November 9, 2018, two days before the 100th Anniversary of the First World
War Armistice, the landscaped Vimy Foundation Centennial Park was officially
opened showcasing 120 of VOLC’s Vimy Oak saplings on land north of the Canadian
National Vimy Memorial. 415 saplings were germinated in France from Vimy acorns
by our partner L’Orme Montferrat Nursery. VOLC also donated 170 saplings to the
French Office National des Forêts (“ONF”). ONF planted them along an allée
leading south of the Vimy Memorial and dedicated them, also on November 9, 2018.
The allée was named “Road of Canadian Memories”. Excess saplings were donated to
four towns along the eight kilometer long Ridge: Givenchy en Gohelle, Souchez ,
Thélus and Villiers Aux Bois. Leslie Miller recorded in his diary visiting the
ruins of these towns after the Vimy Ridge battle to set up wireless listening
posts.`}</p>
    <p>{`The project had its challenges. The removal of unexploded ordnance (“UXO”) still
buried on the site of what is now Centennial Park proved especially difficult
but had to be done to allow for the safe digging of wall and walkway foundations
and holes for planting the trees. After the Park’s opening, the saplings had to
be dug up and replanted due to an error in following our specified planting
procedure by the contractor. Then, a week later, most of the newly re-planted
saplings were blown over by a once-in-fifty-year wind storm as they had not yet
been wind-staked. Remarkably, one year later all but twelve of the trees
survived the early mistakes. The twelve were replaced by nursery backup. Now we
can declare “the `}<strong parentName="p">{`Repatriation Mission`}</strong>{` has been accomplished.” `}<strong parentName="p">{`Leslie
Miller’s Vimy Oaks`}</strong>{` are growing on Vimy Ridge.`}</p>
    <p>{`The project was accomplished in partnership with the Scarborough Chinese Baptist
Church, owner of the Vimy Oaks woodlot and supplier of the acorns, and The Vimy
Foundation (“TVF”). TVF, a registered charity working to preserve and promote
Canada’s ongoing legacy of leadership as symbolized by the First World War
victory at Vimy Ridge, financed the construction of the Centennial Park. Many
volunteers from both Canada and France embraced our cause and contributed to the
project execution, deploying diverse areas of expertise. These included forestry
and horticultural, landscape architecture, project planning and cost
engineering, UXO removal, legal, media, government and public relations,
treasury and finance, federal and local government advocacy, website design and
development and artwork by Canadian Artist Glen Loates for our logo and
honorarium prints. To see all who volunteered, go to the
"`}<Link to="/who-are-the-volc" mdxType="Link">{`Who are the VOLC`}</Link>{`" tab of this website.`}</p>
    <Row mdxType="Row">
  <Column mdxType="Column">
    <Figure image={vimyAerialImage} width={500} mdxType="Figure">
      Aerial View: The Vimy Foundation Centennial Park and Vimy Memorial.
    </Figure>
  </Column>
  <Column mdxType="Column">
    <Figure image={vimyStakedOaksImage} width={500} mdxType="Figure">
      View of Planted, Wind-Staked Vimy Trees at TVF Centennial Park, July 2019.
    </Figure>
  </Column>
    </Row>
    <h2>{`Mission #2: Distribute Oak Trees to Commemorative and Private Locations across Canada`}</h2>
    <p>{`VOLC partnered with NVK Nurseries in growing saplings from both Vimy Oak
graftings and acorns. Starting in 2017, these saplings were offered for sale in
Canada to organizations that were committed to planting them at commemorative
sites such as cenotaphs, town squares, memorial sites and parks, heritage sites,
schools, military cemeteries, Royal Canadian Legions and to TVF for sales to
gain charitable donations. Then, beginning in 2018, VOLC also offered Vimy Oak
saplings for sale to be planted at private sites throughout Canada. All trees
were sold at a price to cover nursery and shipping costs and an administrative
fee that paid for growing and planting Vimy Oak saplings in France. A special
donation of 50 saplings was made to The Canadian Highway of Heroes for which we
waived the administrative fee. As of May 2019, trees were no longer offered to
new applicants. A limited number were reserved for donations to The Vimy
Foundation. The remaining inventory of saplings was reserved for not yet
delivered but committed sales orders and backup to cover die-off. Total sales
reached 1,000 trees covering every province in Canada. “`}<strong parentName="p">{`Mission
Accomplished!`}</strong>{`”`}</p>
    <p><strong parentName="p">{` VOLC will be closing down operations in 2020 but the website will stay alive
to tell our story`}</strong>{`. Any surplus funds from tree sales will be donated to The
Vimy Foundation to go towards annual maintenance of the Centennial Park in
France.`}</p>
    <Row mdxType="Row">
  <Column colSpan={5} mdxType="Column">
    <Figure image={plantedVimyOak1Image} width={350} mdxType="Figure">
      Bob Domoney and Jim Wakefield with a Vimy Oak planted at the Royal
      Canadian Legion Branch in Waterdown Ontario.
    </Figure>
  </Column>
  <Column colSpan={7} mdxType="Column">
    <Figure image={plantedVimyOak2Image} width={539} mdxType="Figure">
      Tree planting at Royal Military College in Kingston in honour of graduates
      who fought in the battle of Vimy Ridge. Donated by the RMC classes of 1967
      and 1973.
    </Figure>
  </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      